"use strict";

import countries from "../assets/countries.json";
export { isSameCountry, getCircularReplacer };

const isSameCountry = (c1, c2) => {
  let c1_data = countries.find(c => c.code == c1 || c.name == snakeToTitle(c1));
  let c2_data = countries.find(c => c.code == c2 || c.name == snakeToTitle(c2));

  return c1_data == c2_data;
};

// Example: JSON.stringify(circularReference, getCircularReplacer());
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#circular_references
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

// Not exported

const snakeToTitle = str =>
  str &&
  str
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
