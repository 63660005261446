export { UTM_SOURCES, PUBLISHERS, PLATFORMS, PIXEL_CV_CODES }

const PUBLISHERS = [
  {id: 2, name: "Comedy.com",         code: "CO",  url: "https://comedy.com/", color: '#8B75D7' },
  {id: 1, name: "Daquan.tv",          code: "DA",  url: "https://daquan.tv/", color: '#FEBC4B'},
  {id: 4, name: "DiscoveryTime.com",  code: "DI",  url: "https://www.discoverytime.com/", color: '#4BC6E6'},
  {id: 7, name: "LuxuryVibes.com",    code: "LX",  url: "https://www.luxuryvibes.com/", color: '#5A8496'},
  {id: 3, name: "MyJournal.com",      code: "JO",  url: "https://www.myjournal.com/", color:  '#FF4139'},  
  {id: 6, name: "SarcasmTherapy.com", code: "SA",  url: "https://www.sarcasmtherapy.com/", color: '#47DDB1'},  
  {id: 5, name: "TheSavagePost.com",  code: "TSP", url: "https://www.thesavagepost.com/", color: '#232323'},
  {id: 8, name: "Newverse.com",       code: "NV",  url: "https://www.newverse.com/", color: '#FF4393'}
];

const PLATFORMS = [
  {name: "facebook",  display: "Facebook",  code: "FB"},
  {name: "pinterest", display: "Pinterest", code: "PI"},
  {name: "snapchat",  display: "Snapchat",  code: "SC"},
  {name: "taboola",   display: "Taboola",   code: "TA"},
  {name: "tiktok",    display: "TikTok",    code: "TT"},
  {name: "twitter",   display: "Twitter",   code: "TW"},
  {name: "yahoo",     display: "Yahoo",     code: "YH"}
];

const UTM_SOURCES = {
  'snapchat': 1001,
  'facebook': 1002,
  'outbrain': 1003,
  'oath': 1004,
  'twitter': 1005,
  'pinterest': 1006,
  'tiktok': 1007,
  'taboola': 1009,
  'reddit': 1010 // Not in used, but reserved for future
};

const PIXEL_CV_CODES = {
  SWIPES: 'SW',
  PIXEL_PURCHASE: '05',
  PIXEL_SIGNUP: '06',
  PIXEL_ADD_TO_CART: '07',
  PIXEL_PAGE_VIEW: 'PV',
  SIGNUP: '07',
  CHECKOUT: '05',
  ADD_TO_CART: '06',
  LEAD: '08'
}