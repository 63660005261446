let logger = null;

class Session {
  constructor(_process, http, host, campaign_id = null) {
    this.process        = _process;
    this.http           = http;
    this.host           = host;
    this.dev            = process.env.NODE_ENV === 'development';
    this.tape_id        = `LS${Date.now()}`;
    this.campaign_id    = campaign_id;
  }

  start() {
    ilog(`${this.dev ? '(dev) ' : ''}initializing logger session`)
  }

  send(severity, msg) {
    const { host, http, process, tape_id } = this;
    let currProcess = this.campaign_id ? `${process} (${this.campaign_id})` : process

    //XXX: Disabling logs from UI for now
    if (true)
      return;

    http.post(`${host}/ui_logs`, { process: currProcess, tape_id, severity, msg })
  }

  bye() {
    ilog('terminating logger session')
    logger = null;
  }

}

const elog = (msg, originalErr = '', values = null) => {
  if (!logger) return;

  logger.send('error', `${msg} => ${toJSON(originalErr)}${" (" + toJSON(values) + ")" || ''}`)
}

const ilog = (msg) => {
  if (!logger) return;
  logger.send('info', msg)
}

const wlog = (msg) => {
  if (!logger) return;
  logger.send('warning', msg)
}

const alog = (action, oldVal, newVal) => {
  if (!logger) return;

  logger.send('info', `${action}: ${toJSON(oldVal)} => ${toJSON(newVal)}`)
}

const LOGGER = {
  initSession(process, http, host, campaign_id) { logger = new Session(process, http, host, campaign_id); logger.start() },
  terminateSession() {
    if (logger) logger.bye();
  },
  getSessionId() {
    return logger ? logger.tape_id : 'missing logger session'
  }
}

export { LOGGER, ilog, elog, wlog, alog}

//private
const toJSON = (obj) => {
  if (obj instanceof File) {
    delete obj.xhr
  }

  return JSON.stringify(obj, Object.getOwnPropertyNames(obj));
}
