"use strict";

import { LAYOUT_OPTIONS as LAYOUT_OPTIONS_FULL } from "../utils/values";

export { dynamicFieldComponent };

const dynamicFieldComponent = type => {
  if (!type) return null;

  return dynamicInputFields.find(df => df.name === type).component;
};

const LAYOUT_OPTIONS = LAYOUT_OPTIONS_FULL.filter(
  layoutOption => layoutOption.value != null
).map(layoutOption => {
  return {
    value: layoutOption.value,
    text: layoutOption.text
  };
});

// TODO: Remove after replaced by devices
const SCREEN_OPTIONS = [
  { value: "desktop", text: "Desktop" },
  { value: "mobile", text: "Mobile" },
  { value: "tablet", text: "Tablet" },
  { value: null, text: "" }
];

const DEVICES_OPTIONS = [
  { value: "desktop", text: "Desktop" },
  { value: "mobile", text: "Mobile" },
  { value: "tablet", text: "Tablet" }
];

const VIDEO_CONTEXTS = [
  { value: "outstream", text: "Outstream" },
  { value: "instream", text: "Instream" }
];

const SLOT_SIZES = [
  { value: [300, 600], text: "300x600" },
  { value: [728, 90], text: "728x90" },
  { value: [970, 250], text: "970x250" },
  { value: [300, 250], text: "300x250" },
  { value: [300, 100], text: "300x100" },
  { value: [320, 50], text: "320x50" },
  { value: [970, 90], text: "970x90" },
  { value: [336, 280], text: "336x280" },
  { value: [300, 1050], text: "300x1050" },
  { value: [468, 60], text: "468x60" },
  { value: [300, 75], text: "300x75" },
  { value: [120, 240], text: "120x240" },
  { value: [250, 250], text: "250x250" },
  { value: [300, 50], text: "300x50" },
  { value: [300, 300], text: "300x300" },
  { value: [120, 600], text: "120x600" },
  { value: [400, 300], text: "400x300" },
  { value: [160, 600], text: "160x600" },
  { value: [320, 100], text: "320x100" }
];

const removeEventSpaces = e => {
  e.preventDefault();
  let left = e.target.value.substring(0, e.target.selectionStart);
  let right = e.target.value.substring(
    e.target.selectionEnd,
    e.target.value.length
  );
  let pasted = (e.dataTransfer || e.clipboardData)
    .getData("text")
    .replace(/ /g, "");
  e.target.value = left + pasted + right;
};

const registerSelf = _this => {
  if (!_this.bidder) return;

  if (_this.value.type === "boolean") {
    _this.bidder.checkbox = _this;
  } else if (["string", "integer"].includes(_this.value.type)) {
    _this.bidder.siblings = _this.bidder.siblings || [];
    _this.bidder.siblings.push(_this);
  }
};

const updateIsAdUnitActive = (_this, val) => {
  if (!_this.bidder.checkbox) return;

  if (!val) {
    _this.bidder.checkbox.value.value = false;
    return;
  }

  let hasEmptyInput = false;
  _this.bidder.siblings.forEach(input => {
    if (!input.value.value) hasEmptyInput = true;
  });
  _this.bidder.checkbox.value.value = !hasEmptyInput;
};

const dynamicInputFields = [
  {
    name: "string",
    component: {
      props: { value: Object, bidder: Object },
      mounted() {
        registerSelf(this);
      },
      methods: { removeEventSpaces },
      watch: {
        "value.value": function(val) {
          this.$eventHub.$emit("fieldChanged");
          updateIsAdUnitActive(this, val);
        }
      },
      template: `<b-form-input @keydown.space.prevent @paste="removeEventSpaces" @drop="removeEventSpaces" v-model="value.value" />`
    }
  },
  {
    name: "boolean",
    component: {
      props: { value: Object, bidder: Object, isSSPActive: Boolean },
      mounted() {
        registerSelf(this);
      },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-checkbox switch v-model="value.value" :disabled="!isSSPActive"/>`
    }
  },
  {
    name: "integer",
    component: {
      props: { value: Object, bidder: Object },
      mounted() {
        registerSelf(this);
      },
      watch: {
        "value.value": function(val) {
          this.$eventHub.$emit("fieldChanged");
          updateIsAdUnitActive(this, val);
        }
      },
      template: `<b-form-input style="width:120px" v-model.number="value.value" type="number" onwheel="return false;" onkeypress="return event.charCode >= 48" min="1" />`
    }
  },
  // TODO: Remove after replaced by devices
  {
    name: "screen",
    component: {
      data: function() {
        return { screenOptions: SCREEN_OPTIONS };
      },
      props: { value: Object },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-select v-model="value.value" :options="screenOptions" />`
    }
  },
  {
    name: "devices",
    component: {
      data: function() {
        return { devicesOptions: DEVICES_OPTIONS };
      },
      props: { value: Object },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-select style="width:100px" v-model="value.value" :options="devicesOptions" multiple :select-size="3" />`
    }
  },
  {
    name: "layout",
    component: {
      data: function() {
        return { layoutOptions: LAYOUT_OPTIONS };
      },
      props: { value: Object },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-select style="width:220px" v-model="value.value" :options="layoutOptions" multiple :select-size="4" />`
    }
  },
  {
    name: "slot_sizes",
    component: {
      data: function() {
        return { slotSizes: SLOT_SIZES };
      },
      props: { value: Object },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-select style="width:100px" v-model="value.value" :options="slotSizes" multiple :select-size="4" />`
    }
  },
  {
    name: "video_context",
    component: {
      data: function() {
        return { videoContexts: VIDEO_CONTEXTS };
      },
      props: { value: Object },
      watch: {
        "value.value": function() {
          this.$eventHub.$emit("fieldChanged");
        }
      },
      template: `<b-form-select v-model="value.value" :options="videoContexts" />`
    }
  }
];
