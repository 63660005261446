export { ICONS, DEVICES_DISPLAY }

const DEVICES_DISPLAY = {
  title(devices) {
    if (devices.length > 1) {
      if (devices.every(d => ['android', 'ios', 'other_mobile'].includes(d)))
        return 'Mobile';
      else if (devices.every(d => d.includes('android')))
        return 'Android'
      else if (devices.every(d => ['iphone', 'ipad'].includes(d)))
        return'iOS'
      else if (!devices.every(v => v == devices[0]))
        return 'Multiple devices'
    }

    let device = devices[0];
    switch (device) {
      case 'ios':
        return 'iOS'
      case 'android':
        return 'Android'
      case 'pc':
        return 'Desktop'
      case 'all':
        return 'All devices';
      default:
        return ''
    }
  },
  icon(devices) {
    let device_type = devices[0]

    if (devices.length > 1) {
      if (devices.every(d => ['android', 'ios', 'other_mobile'].includes(d)))
        device_type = 'mobile'
      else if (devices.every(d => d.includes('android')))
        device_type = 'android'
      else if (devices.every(d => ['iphone', 'ipad'].includes(d)))
        device_type = 'ios'
      else if (!devices.every(d => d == devices[0]))
        device_type = 'mixed'
    }

    return ICONS.devices[device_type]
  }
}

const ICONS = {
  status: {
    paused:       'fa-pause',
    running:      'fa-play',
    pending:      'fa-spinner fa-pulse',
    creating:     'fa-cog fa-spin',
    mismatch:     'fa-exclamation-triangle',
    rejected:     'fa-times',
    in_review:    'fa-eye',
    terminated:   'fa-trash-alt',
    create_error: 'fa-exclamation-triangle',
  },
  devices: {
    ios: 'fab fa-apple',
    android: 'fab fa-android',
    pc: 'fas fa-desktop',
    all: 'far fa-phone-laptop',
    mixed: 'far fa-phone-laptop',
    mobile: 'fas fa-mobile-alt'
  }
}
