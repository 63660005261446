const ages = [
  "Any",
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
];
const def = {
  gender: [
    { name: "ALL", code: "all_genders" },
    { name: "Male", code: "male" },
    { name: "Female", code: "female" },
  ],
  age_min: ages,
  age_max: ages,
  locales: [
    { name: "English", code: "en" },
    { name: "Spanish", code: "es" },
    { name: "French", code: "fr" },
    { name: "German", code: "de" },
    { name: "Italian", code: "it" },
    { name: "Portuguese", code: "pt" },
    { name: "Japanese", code: "ja" },
  ],
  defaults: {},
  browsers: [],
  platforms: [
    { name: "iOS", code: "ios" },
    { name: "Android", code: "android" },
  ],
  adset_name: true,
  ad_caption: true,
  restrictions: {},
  daily_budget: { adset: true, campaign: true },
  headline_name: "Headline",
  operating_systems: [],
  supports_multi_adsets: false,
};

const google = JSON.parse(JSON.stringify(def));
const referral = JSON.parse(JSON.stringify(def));
const taboola = JSON.parse(JSON.stringify(def));
delete taboola.age_min;
delete taboola.age_max;
taboola.browsers = [
  { name: "Chrome", code: "chrome" },
  { name: "Safari", code: "safari" },
  { name: "Firefox", code: "firefox" },
  { name: "Internet Explorer", code: "internet_explorer" },
  { name: "Edge", code: "edge" },
  { name: "Opera", code: "opera" },
  { name: "Samsung Browser", code: "samsung" },
  { name: "Facebook In-App Browser", code: "fb_in_app" },
  { name: "Chromium", code: "chromium" },
  { name: "WebView", code: "webView" },
  { name: "Google Search App", code: "gg_search_app" },
  { name: "Android App", code: "android_app" },
  { name: "MIUI Browser", code: "miui" },
  { name: "Amazon Silk", code: "amazon_silk" },
  { name: "UC Browser", code: "uc_browser" },
  { name: "Yandex", code: "yandex" },
];
taboola.platforms = [
  { name: "Desktop", code: "pc" },
  { name: "Mobile", code: "mobile" },
  { name: "Tablet", code: "tablet" },
];
taboola.locales = [];
taboola.objectives = ["DRIVE_WEBSITE_TRAFFIC", "LEADS_GENERATION"];
taboola.adset_name = false;
taboola.ad_caption = false;
taboola.daily_budget = { adset: false, campaign: true };
taboola.operating_systems = [
  { name: "iOS", code: "ios" },
  { name: "Android", code: "android" },
  { name: "Mac OS X", code: "mac_os" },
  { name: "Linux", code: "linux" },
  { name: "Windows", code: "windows" },
];
taboola.restrictions = {
  image: [
    { min_width: 1072, min_height: 603 },
    { min_width: 800, min_height: 600 },
    { min_width: 600, min_height: 600 },
  ],
};
taboola.color = "#0372B9";

const facebook = JSON.parse(JSON.stringify(def));
facebook.platforms = [
  { name: "iOS", code: "ios" },
  { name: "Android", code: "android" },
  { name: "All Desktop", code: "pc" },
  { name: "All Mobile", code: "mobile" },
];
facebook.supports_multi_adsets = true;
// https://developers.facebook.com/docs/marketing-api/audiences/reference/targeting-search#locale
facebook.locales = [
  {
    name: "English (All)",
    code: "1001",
  },
  {
    name: "Spanish (All)",
    code: "1002",
  },
  {
    name: "French (All)",
    code: "1003",
  },
  {
    name: "German",
    code: "5",
  },
  {
    name: "Italian",
    code: "10",
  },
  {
    name: "Japanese",
    code: "11",
  },
  {
    name: "Portuguese (All)",
    code: "1005",
  },
  {
    name: "English (US)",
    code: "6",
  },
  {
    name: "Catalan",
    code: "1",
  },
  {
    name: "Czech",
    code: "2",
  },
  {
    name: "Cebuano",
    code: "56",
  },
  {
    name: "Welsh",
    code: "3",
  },
  {
    name: "Danish",
    code: "4",
  },

  {
    name: "Basque",
    code: "59",
  },
  {
    name: "Spanish",
    code: "23",
  },
  {
    name: "Spanish (Spain)",
    code: "7",
  },
  {
    name: "Guarani",
    code: "66",
  },
  {
    name: "Finnish",
    code: "8",
  },
  {
    name: "French (France)",
    code: "9",
  },
  {
    name: "Galician",
    code: "65",
  },
  {
    name: "Hungarian",
    code: "30",
  },
  {
    name: "Korean",
    code: "12",
  },
  {
    name: "Norwegian (bokmal)",
    code: "13",
  },
  {
    name: "Norwegian (nynorsk)",
    code: "84",
  },
  {
    name: "Dutch",
    code: "14",
  },
  {
    name: "Frisian",
    code: "63",
  },
  {
    name: "Polish",
    code: "15",
  },
  {
    name: "Portuguese (Brazil)",
    code: "16",
  },
  {
    name: "Portuguese (Portugal)",
    code: "31",
  },
  {
    name: "Romanian",
    code: "32",
  },
  {
    name: "Russian",
    code: "17",
  },
  {
    name: "Slovak",
    code: "33",
  },
  {
    name: "Slovenian",
    code: "34",
  },
  {
    name: "Swedish",
    code: "18",
  },
  {
    name: "Thai",
    code: "35",
  },
  {
    name: "Turkish",
    code: "19",
  },
  {
    name: "Kurdish (Kurmanji)",
    code: "76",
  },
  {
    name: "Simplified Chinese (China)",
    code: "20",
  },
  {
    name: "Traditional Chinese (Hong Kong)",
    code: "21",
  },
  {
    name: "Traditional Chinese (Taiwan)",
    code: "22",
  },
  {
    name: "Afrikaans",
    code: "36",
  },
  {
    name: "Albanian",
    code: "87",
  },
  {
    name: "Armenian",
    code: "68",
  },
  {
    name: "Azerbaijani",
    code: "53",
  },
  {
    name: "Belarusian",
    code: "54",
  },
  {
    name: "Bengali",
    code: "45",
  },
  {
    name: "Bosnian",
    code: "55",
  },
  {
    name: "Bulgarian",
    code: "37",
  },
  {
    name: "Croatian",
    code: "38",
  },
  {
    name: "Dutch (België)",
    code: "83",
  },
  {
    name: "English (UK)",
    code: "24",
  },
  {
    name: "Esperanto",
    code: "57",
  },
  {
    name: "Estonian",
    code: "58",
  },
  {
    name: "Faroese",
    code: "62",
  },
  {
    name: "French (Canada)",
    code: "44",
  },
  {
    name: "Georgian",
    code: "72",
  },
  {
    name: "Greek",
    code: "39",
  },
  {
    name: "Gujarati",
    code: "67",
  },
  {
    name: "Hindi",
    code: "46",
  },
  {
    name: "Icelandic",
    code: "69",
  },
  {
    name: "Indonesian",
    code: "25",
  },
  {
    name: "Irish",
    code: "64",
  },
  {
    name: "Javanese",
    code: "71",
  },
  {
    name: "Kannada",
    code: "75",
  },
  {
    name: "Kazakh",
    code: "73",
  },
  {
    name: "Latvian",
    code: "78",
  },
  {
    name: "Lithuanian",
    code: "40",
  },
  {
    name: "Macedonian",
    code: "79",
  },
  {
    name: "Malay",
    code: "41",
  },
  {
    name: "Marathi",
    code: "81",
  },
  {
    name: "Mongolian",
    code: "80",
  },
  {
    name: "Nepali",
    code: "82",
  },
  {
    name: "Punjabi",
    code: "47",
  },
  {
    name: "Serbian",
    code: "42",
  },
  {
    name: "Swahili",
    code: "88",
  },
  {
    name: "Filipino",
    code: "26",
  },
  {
    name: "Tamil",
    code: "48",
  },
  {
    name: "Telugu",
    code: "49",
  },
  {
    name: "Malayalam",
    code: "50",
  },
  {
    name: "Ukrainian",
    code: "52",
  },
  {
    name: "Uzbek",
    code: "91",
  },
  {
    name: "Vietnamese",
    code: "27",
  },
  {
    name: "Khmer",
    code: "74",
  },
  {
    name: "Tajik",
    code: "89",
  },
  {
    name: "Arabic",
    code: "28",
  },
  {
    name: "Hebrew",
    code: "29",
  },
  {
    name: "Urdu",
    code: "90",
  },
  {
    name: "Persian",
    code: "60",
  },
  {
    name: "Pashto",
    code: "85",
  },
  {
    name: "Sinhala",
    code: "86",
  },
  {
    name: "Japanese (Kansai)",
    code: "70",
  },
  {
    name: "Chinese (All)",
    code: "1004",
  },
];

facebook.objectives = ["LINK_CLICKS", "OUTCOME_LEADS"];
facebook.daily_budget = { adset: false, campaign: true };
facebook.call_to_action = [
  "NO_BUTTON",
  "LEARN_MORE",
  "WATCH_MORE",
  "LISTEN_NOW",
  "GET_QUOTE",
];
facebook.restrictions = {
  image: [
    { min_width: 612, min_height: 320 },
    { min_width: 612, min_height: 612 },
  ],
  video: [
    { min_width: 120, min_height: 480 },
    { min_width: 480, min_height: 120 },
  ],
};
facebook.defaults.adset = {
  gender: "all_genders",
  age_min: 21,
  age_max: 65,
  auto_placement: true,
};
facebook.defaults.ad = {
  call_to_action: "LEARN_MORE",
};
facebook.color = "#264B9D";

const snapchat = JSON.parse(JSON.stringify(def));
snapchat.objectives = ["WEB_CONVERSION", "WEB_VIEW"];
snapchat.ad_caption = false;
snapchat.headline_name = "System Title";
snapchat.call_to_action = ["MORE", "PLAY", "READ", "SHOW", "VIEW", "WATCH"];
snapchat.supports_multi_adsets = true;
snapchat.goals = {
  STORY: ["STORY_OPENS", "PIXEL_PURCHASE", "PIXEL_SIGNUP"],
  WEB_VIEW: ["SWIPES"],
  WEB_CONVERSION: [
    "SWIPES",
    "PIXEL_PURCHASE",
    "PIXEL_SIGNUP",
    "PIXEL_ADD_TO_CART",
    "PIXEL_PAGE_VIEW",
  ],
};
snapchat.restrictions = {
  image: [{ min_width: 1080, min_height: 1920 }],
  video: [{ min_width: 1080, min_height: 1920 }],
  preview_image: [{ min_width: 360, min_height: 600 }],
  headline_max_chars: 34,
};
snapchat.defaults.ad = {
  call_to_action: "first",
};
snapchat.color = "#EDB808";

const twitter = JSON.parse(JSON.stringify(def));
twitter.age_min = ["Any", 13, 18, 21, 25, 35, 50];
twitter.age_max = ["Any", 24, 34, 49, 54];
twitter.platforms = [
  { name: "iOS", code: "ios" },
  { name: "Android", code: "android" },
  { name: "Other mobile", code: "other_mobile" },
  { name: "Desktop and laptop computers", code: "pc" },
];
twitter.objectives = ["WEBSITE_CLICKS", "WEBSITE_CONVERSIONS"];
twitter.daily_budget = { adset: false, campaign: true };
twitter.restrictions = {
  image: [
    { min_width: 800, min_height: 418 },
    { min_width: 800, min_height: 800 },
  ],
  video: [
    { min_width: 743, min_height: 418 },
    { min_width: 743, min_height: 743 },
  ],
  headline_max_chars: 70,
};
twitter.color = "#07D1E2";

const pinterest = JSON.parse(JSON.stringify(def));
pinterest.age_min = ["Any", 18, 25, 35, 45, 50, 55, 65];
pinterest.age_max = ["Any", 24, 34, 44, 49, 54, 64];
pinterest.platforms = [
  { name: "iPhone", code: "iphone" },
  { name: "Android Mobile", code: "android" },
  { name: "Web", code: "pc" },
  { name: "Mobile Web", code: "web_mobile" },
  { name: "iPad", code: "ipad" },
  { name: "Android Tablet", code: "android_tablet" },
];
pinterest.locales = [
  { name: "English", code: "en" },
  { name: "Spanish", code: "es" },
  { name: "Czech", code: "cs" },
  { name: "Greek", code: "el" },
  { name: "Chinese", code: "zh" },
  { name: "Italian", code: "it" },
  { name: "Indonesian", code: "id" },
  { name: "Russian", code: "ru" },
  { name: "Dutch", code: "nl" },
  { name: "Portuguese", code: "pt" },
  { name: "Norwegian", code: "nb" },
  { name: "Turkish", code: "tr" },
  { name: "Romanian", code: "ro" },
  { name: "Polish", code: "pl" },
  { name: "French", code: "fr" },
  { name: "German", code: "de" },
  { name: "Danish", code: "da" },
  { name: "Finnish", code: "fi" },
  { name: "Hungarian", code: "hu" },
  { name: "Japanese", code: "ja" },
  { name: "Korean", code: "ko" },
  { name: "Swedish", code: "sv" },
  { name: "Slovak", code: "sk" },
  { name: "Ukrainian", code: "uk" },
];
pinterest.objectives = ["WEB_CONVERSION", "TRAFFIC"];
pinterest.boards = {
  // key is publisher id
  1: [
    { name: "Funny", external_id: "650699914850510159" },
    { name: "History", external_id: "650699914850510311" },
    { name: "Throwback", external_id: "650699914850510312" },
    { name: "Vintage Celebrities", external_id: "650699914850510313" },
    { name: "Celebrities", external_id: "650699914850510315" },
    { name: "Featured Stories", external_id: "650699914850510316" },
    { name: "Protected Board 08", external_id: "650699914850510322" },
    { name: "test_protected", external_id: "650699914850510104" },
  ],
  2: [
    { name: "Funny", external_id: "648870327501902206" },
    { name: "History", external_id: "648870327501902209" },
    { name: "Throwback", external_id: "648870327501902207" },
    { name: "Vintage", external_id: "648870327501902210" },
    { name: "Celebrities", external_id: "648870327501902211" },
    { name: "Featured Stories", external_id: "648870327501902214" },
    { name: "Protected_03", external_id: "648870327501902194" },
    { name: "Protected_04", external_id: "648870327501902197" },
    { name: "Protected_05", external_id: "648870327501902199" },
    { name: "Protected_06", external_id: "648870327501902201" },
    { name: "Protected_07", external_id: "648870327501902203" },
    { name: "Protected_08", external_id: "648870327501902204" },
  ],
};
pinterest.goals = {
  WEB_CONVERSION: ["SIGNUP", "CHECKOUT", "ADD_TO_CART", "LEAD"],
};
pinterest.restrictions = {
  image: [
    { min_width: 600, min_height: 900 },
    { min_width: 600, min_height: 600 },
    { min_width: 600, min_height: 1260 },
    { min_width: 1080, min_height: 1920 },
  ],
  video: [
    { min_width: 1000, min_height: 1000 },
    { min_width: 1000, min_height: 1500 },
    { min_width: 1080, min_height: 1920 },
  ],
  headline_max_chars: 100,
};
pinterest.supports_multi_adsets = true;
pinterest.color = "#BE0F0F";

const tiktok = JSON.parse(JSON.stringify(def));
tiktok.color = "#000000";

const yahoo = JSON.parse(JSON.stringify(def));
yahoo.color = "#6916D5";

export default {
  facebook: facebook,
  snapchat: snapchat,
  twitter: twitter,
  pinterest: pinterest,
  taboola: taboola,
  tiktok: tiktok,
  yahoo: yahoo,
  google: google,
  referral: referral,
};
