"use strict";
import moment from "moment";

const AVAILABLE_TIMEZONES = [
  { label: "UTC", value: "UTC" },
  { label: "EST", value: "America/New_York" },
  { label: "PST", value: "America/Los_Angeles" },
];

const END_OF_ARTICLE_OPTIONS = [
  { value: null, text: "Please select EOA" },
  { value: 1, text: "Next Collection" },
  { value: 2, text: "Taboola Feed" },
];

const LAYOUT_OPTIONS = [
  {
    value: null,
    text: "Please select a layout",
    required: { eoa: false, single_ad: false, double_ad: false, inter: false },
  },
  {
    value: 1,
    text: "Infinite - default",
    shortName: "Infinite",
    required: { eoa: true, single_ad: true, double_ad: true, inter: false },
  },
  {
    value: 2,
    text: "Gallery - Centered content",
    shortName: "Centered content",
    required: { eoa: false, single_ad: false, double_ad: false, inter: true },
  },
  {
    value: 3,
    text: "Gallery - Left-sided content",
    shortName: "Left-sided content",
    required: { eoa: false, single_ad: false, double_ad: false, inter: true },
  },
  {
    value: 4,
    text: "Hybrid - Gallery + Infinite",
    shortName: "Hybrid",
    required: { eoa: true, single_ad: true, double_ad: true, inter: false },
  },
  {
    value: 5,
    text: "Ecommerce",
    shortName: "Ecommerce",
    required: { eoa: true, single_ad: true, double_ad: true, inter: false },
  },
];

const VIDEO_PLAYER_OPTIONS = [
  { value: null, text: "Please select a video player" },
  { value: "1", text: "Primis" },
  { value: "2", text: "Taboola" },
  { value: "3", text: "MinuteMedia" },
  { value: "4", text: "Vidazoo" },
  { value: "5", text: "Rise" },
  { value: "6", text: "Play360vid" },
  { value: "0", text: "🚫 No Video" },
];

const SNAP_AD_OPTIONS = [
  { value: "single", text: "Single Image/Video" },
  { value: "story", text: "Story Ad" },
];

const BUDGET_TYPES = [
  { value: "daily", text: "Daily" },
  { value: "lifetime", text: "Lifetime" },
];

const datePickerRanges = () => {
  return {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(7, "days"), moment().subtract(1, "days")],
    "Last 14 Days": [
      moment().subtract(14, "days"),
      moment().subtract(1, "days"),
    ],
    "Last 30 Days": [
      moment().subtract(30, "days"),
      moment().subtract(1, "days"),
    ],
    "Last 180 Days": [
      moment().subtract(180, "days"),
      moment().subtract(1, "days"),
    ],
    "This Month": [moment().startOf("month"), moment()],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "This Year": [moment().startOf("year"), moment()],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };
};

const tinyOptions = (formatter) => {
  return {
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    grid: {
      show: false,
    },
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    annotations: {
      xaxis: [],
    },
    xaxis: {
      show: false,
      type: "datetime",
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        show: false,
        labels: {
          formatter: formatter,
        },
      },
    ],
    tooltip: {
      x: {
        show: false,
        format: "dd MMM yyyy HH:mm",
      },
    },
  };
};

export {
  AVAILABLE_TIMEZONES,
  SNAP_AD_OPTIONS,
  datePickerRanges,
  tinyOptions,
  LAYOUT_OPTIONS,
  END_OF_ARTICLE_OPTIONS,
  VIDEO_PLAYER_OPTIONS,
  BUDGET_TYPES,
};
