import numeral from "numeral";
import _ from "lodash";

export { STORAGE, COLUMNS };

const COLUMNS_DATA = {
  realtimeUsers: {
    header: "RT Users",
    format(content) {
      return content[this.name]
        ? numeral(content[this.name]).format("0,0")
        : "";
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("0,0");
    },
  },
  units: {
    // item is a unit of paragraph + picture + ad in an article. aka pages
    header: "Items",
    isMetricsAgg: true,
    cssClass: () => "units",
    format(content) {
      let totals = "";
      if (content.article_units) {
        totals = ` / ${content.article_units} (${(
          (content[this.name] * 100.0) /
          content.article_units
        ).toFixed(0)}%)`;
      }
      return `${numeral(content[this.name]).format("0,0.0")}${totals}`;
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("0,0.0");
    },
  },
  source_impressions: {
    // aka reach
    header: "Reach",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("0,0");
    },
  },
  clicks: {
    header: "Clicks",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("0,0");
    },
  },
  lands: {
    header: "Visits",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("0,0");
    },
  },
  visits: {
    header: "Sessions",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("0,0");
    },
  },
  bounce: {
    header: "Bounce %",
    isMetricsAgg: true,
    format(content) {
      return content[this.name] < 0
        ? "0%"
        : `${numeral(content[this.name]).format("0,0.0")}%`;
    },
    formatTotals(totals) {
      return totals[this.name] < 0
        ? "0%"
        : `${numeral(totals[this.name]).format("0,0.0")}%`;
    },
  },
  visit_bounce: {
    header: "Visit Bounce %",
    isMetricsAgg: true,
    format(content) {
      return content[this.name] < 0
        ? "0%"
        : `${numeral(content[this.name]).format("0,0.0")}%`;
    },
    formatTotals(totals) {
      return totals[this.name] < 0
        ? "0%"
        : `${numeral(totals[this.name]).format("0,0.0")}%`;
    },
  },
  imps_per_lands: {
    header: "Ads Per Visit",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0.0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("0,0.0");
    },
  },
  imps_per_visit: {
    header: "Ads Per Session",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0.0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("0,0.0");
    },
  },
  rpm: {
    header: "RPM",
    isMetricsAgg: true,
    format(content) {
      return `$${parseFloat(content[this.name] || 0).toFixed(2)}`;
    },
    formatTotals(totals) {
      return `$${parseFloat(totals[this.name] || 0).toFixed(2)}`;
    },
  },
  est_rpm: {
    header: "Est. RPM",
    isMetricsAgg: true,
    format(content) {
      return `$${parseFloat(content[this.name] || 0).toFixed(2)}`;
    },
    formatTotals(totals) {
      return `$${parseFloat(totals[this.name] || 0).toFixed(2)}`;
    },
  },
  user_value: {
    header: "User Value",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  est_visit_value: {
    header: "Est. Visit Value",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  est_session_value: {
    header: "Est. Session Value",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  visit_value: {
    header: "Visit Value",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  cpc: {
    // cost per click (payment to platform is per click, not per impression)
    header: "CPC",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  ctr: {
    header: "CTR%",
    isMetricsAgg: true,
    format(content) {
      return `${parseFloat(content[this.name] || 0).toFixed(2)}%`;
    },
    formatTotals(totals) {
      return `${parseFloat(totals[this.name] || 0).toFixed(2)}%`;
    },
    customElement: {
      cssClass: () => "hash-code",
      format(content) {
        return content.active_creatives;
      },
    },
  },
  cpm: {
    header: "CPM",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  vctr: {
    header: "vCTR%",
    isMetricsAgg: true,
    format(content) {
      return `${parseFloat(content[this.name] || 0).toFixed(2)}%`;
    },
    formatTotals(totals) {
      return `${parseFloat(totals[this.name] || 0).toFixed(2)}%`;
    },
  },
  vcpm: {
    header: "vCPM",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  cpa: {
    header: "CPA",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.000");
    },
    formatTotals(totals) {
      return totals.results > 0
        ? numeral(totals[this.name]).format("$0,0.000")
        : 0;
    },
  },
  results: {
    header: "Results",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("0,0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("0,0");
    },
  },
  result_rate: {
    header: "Result Rate %",
    isMetricsAgg: true,
    format(content) {
      return `${numeral(content[this.name] || 0).format("0.00")}%`;
    },
    formatTotals(totals) {
      return `${numeral(totals[this.name] || 0).format("0.00")}%`;
    },
  },
  revenue: {
    header: "Revenue",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("$0,0.00");
    },
  },
  spend: {
    header: "Spend",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.00");
    },
  },
  profit: {
    header: "Profit",
    isMetricsAgg: true,
    cssClass: (content) =>
      `${(content.profit || 0) >= 0 ? "text-success" : "text-danger"}`,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.00");
    },
  },
  est_revenue: {
    header: "Est. Revenue",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name]).format("$0,0.00");
    },
  },
  est_spend: {
    header: "Est. Spend",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.00");
    },
  },
  est_profit: {
    header: "Est. Profit",
    isMetricsAgg: true,
    cssClass: (content) =>
      `${(content.est_profit || 0) >= 0 ? "text-success" : "text-danger"}`,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.00");
    },
  },
  est_profit_real_spend: {
    header: "Est. Profit Real Spend",
    isMetricsAgg: true,
    cssClass: (content) =>
      `${
        (content.est_profit_real_spend || 0) >= 0
          ? "text-success"
          : "text-danger"
      }`,
    format(content) {
      return numeral(content[this.name] || 0).format("$0,0.00");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.00");
    },
  },
  est_margin: {
    header: "Est. Margin",
    isMetricsAgg: true,
    format(content) {
      return `${numeral(content[this.name] || 0).format("0.00")}%`;
    },
    formatTotals(totals) {
      return `${numeral(totals[this.name] || 0).format("0.00")}%`;
    },
  },
  roi: {
    header: "ROI",
    isMetricsAgg: true,
    cssClass: (content) =>
      `${(content.roi || 0) >= 100 ? "text-success" : "text-danger"}`,
    format(content) {
      return `${numeral(content[this.name] || 0).format("0,0.00")}%`;
    },
    formatTotals(totals) {
      return `${numeral(totals[this.name] || 0).format("0,0.00")}%`;
    },
  },
  margin: {
    header: "Margin",
    isMetricsAgg: true,
    format(content) {
      return `${parseFloat(content[this.name] || 0).toFixed(2)}%`;
    },
    formatTotals(totals) {
      return `${parseFloat(totals[this.name] || 0).toFixed(2)}%`;
    },
  },
  vrpv: {
    header: "vRPV",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  cv: {
    header: "CV",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("$0,0.000");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("$0,0.000");
    },
  },
  ads_per_item: {
    header: "Ads Per Item",
    isMetricsAgg: true,
    format(content) {
      return numeral(content[this.name]).format("0,0.0");
    },
    formatTotals(totals) {
      return numeral(totals[this.name] || 0).format("0,0.0");
    },
  },
  quality: {
    header: "Ad Quality",
    format(content) {
      return content.has_low_quality ? content[this.name] : "-";
    },
    formatTotals() {
      return "";
    },
  },
  avg_session: {
    header: "Session Duration",
    format(content) {
      return numeral(content[this.name] || 0).format("00:00:00");
    },
    formatTotals() {
      return "";
    },
  },
  negative_sentiment: {
    header: "Negative Sentiment",
    cssClass: (content) =>
      content.negative_sentiment > 10 ? "text-danger" : "",
    format(content) {
      return content[this.name]
        ? `${parseFloat(content[this.name]).toFixed(1)}%`
        : "";
    },
    formatTotals() {
      return "";
    },
  },
  comments: {
    header: "Comments",
    format(content) {
      return content[this.name]
        ? numeral(content[this.name]).format("0,0")
        : "";
    },
    formatTotals() {
      return "";
    },
  },
};

let columnsDataArr = [];

if (!localStorage.getItem("metricsOrder")) {
  const defaultMerticsOrder = Object.keys(COLUMNS_DATA).map(
    (metricName) => metricName
  );
  orderColumns(defaultMerticsOrder);
} else {
  orderColumns(JSON.parse(localStorage.getItem("metricsOrder")));
}

function orderColumns(metricsOrder) {
  metricsOrder.forEach((metrics, i) => {
    COLUMNS_DATA[metrics].order = i + 1;
  });
  localStorage.setItem("metricsOrder", JSON.stringify(metricsOrder));

  columnsDataArr = _.orderBy(
    Object.entries(COLUMNS_DATA).map((col) => {
      col[1]["name"] = col[0];
      return col[1];
    }),
    ["order"]
  ); // to preserve order
}

const STORAGE = {
  set(selected, order) {
    localStorage.setItem("selectedColumns", JSON.stringify(selected));
    orderColumns(order);
  },

  get() {
    let selectedColumnString = localStorage.getItem("selectedColumns");

    if (!selectedColumnString) {
      let allCols = columnsDataArr.map((col) => col.name);
      this.set(allCols);
      return allCols;
    }

    return JSON.parse(selectedColumnString);
  },
};

const COLUMNS = {
  getHeaders: (() => {
    let cols = {};
    columnsDataArr.forEach((column) => {
      cols[column.name] = column.header;
    });

    return cols;
  })(),
  getSelectedData(selectedCols) {
    return columnsDataArr.filter((col) => _.includes(selectedCols, col.name));
  },
  isColMetricsAgg(colName) {
    return COLUMNS_DATA[colName].isMetricsAgg;
  },
};
