export function formatTime(isoTime) {
    const date = new Date(isoTime);
    const dateString = date.toLocaleTimeString([], {
        hour12: false,
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit'
    });

    return dateString === '24:00' ? '00:00' : dateString;
}

function convertServerJsonToFormRecursive(json) {
    const groups = json.children.filter(child => !!child.children);
    const rules = json.children.filter(child => !child.children);
    for (const group of groups) convertServerJsonToFormRecursive(group);

    const newProps = {
        query: {
            logicalOperator: json.logical_operator,
            children: json.children
        },
        type: 'query-builder-group'
    };
    if (json.range) newProps.query.range = json.range;

    overrideObject(json, newProps);

    for (const rule of rules) {
        overrideObject(rule, {
            query: {
                operator: rule.operator,
                operand: rule.field,
                rule: rule.field,
                value: rule.value
            },
            type: 'query-builder-rule'
        });
    }

    return json;
}

export function convertServerJsonToForm(json) {
    if (!json) return null;

    const clone = JSON.parse(JSON.stringify(json));

    return convertServerJsonToFormRecursive(clone).query;
}

function convertFormJsonToServerRecursive(json) {
    const groups = json.query.children.filter(child => !!child.query.children);
    const rules = json.query.children.filter(child => !child.query.children);
    for (const group of groups) convertFormJsonToServerRecursive(group);

    const newProps = {
        children: json.query.children,
        logical_operator: json.query.logicalOperator
    };
    if (json.query.range) newProps.range = json.query.range;

    overrideObject(json, newProps);

    for (const rule of rules) {
        overrideObject(rule, {
            field: rule.query.rule,
            operator: rule.query.operator,
            value: rule.query.value
        });
    }

    return json;
}

export function convertFormJsonToServer(json) {
    if (!json) return null;

    const clone = JSON.parse(JSON.stringify(json));

    return convertFormJsonToServerRecursive(clone);
}

function overrideObject(target, source) {
    for (const prop in target) delete target[prop];
    Object.assign(target, source);
}
